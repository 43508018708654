import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>ERFAHREN SIE WIE SICH UNSERE PRODUKTE UNTERSCHEIDEN</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkte/',
    label: 'ALLE PRODUKTE ANSEHEN',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Bei stark verschmutztem Geschirr</li>\n<li><sup>**</sup>vs. Finish Power basierend auf dem Durchschnittsgewicht</li>\n<li><sup>***</sup>Inhaltsstoff: Enzym</li>\n</ul>',
      rows: [
        {
          text: 'Reinigung',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt82888abe65a3cf92/66a8bd75f24439cde173be08/clean.svg',
        },
        {
          text: 'Gland',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4068f74db0e98470/66a8bdf6b8115e2482a37a72/shine.svg',
        },
        {
          text: 'Entfernt 48h Eingetrocknetes<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4b1a83960fae2f42/66a8bd75b8115e47cca37a6d/dried-on.svg',
        },
        {
          text: 'Fettlösekraft',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltef4110afe98d3440/66a8bdf6f24439951073be0f/tough-on-grease.svg',
        },
        {
          text: 'Kein Vorspülen',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltb35fc648c6362147/66a8bdf61097ec6bcaa86bf5/skip-the-rinse.svg',
        },
        {
          text: 'Ohne Auspacken',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt906d9e1af7cf7cf8/66a8bd7575e8676dacd73cd9/wrapper-free-tabs.svg',
        },
        {
          text: 'Schnellauflösend',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt84a3d1465f181c87/66a8bdf67da9b43d0cb481c0/fast-dissolving.svg',
        },
        {
          text: 'Weniger Chemikaliengehal<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt0b422bd29369e3e4/66a8bdf6739577514719fa1a/less-chemical-weight.svg',
        },
        {
          text: 'Glasschutz',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blta20864530554004a/66a8bd751a892d24844a3ac8/glass-protection.svg',
        },
        {
          text: 'Verleiht mehr Frische',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt95a23ce6c566f108/66a8bd7517cd4f0b596f724a/freshness-boost.svg',
        },
        {
          text: 'Entfernt Eingebranntes effektiv',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf4e3970c7de0d7ea/66a8bd7506435dc50cddea0b/burnt-on-stains.svg',
        },
        {
          text: 'Richtiger Inhaltsstoff<sup>***</sup> zur richtigen Zeit',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkte/reiniger/ultimate-plus-spuelmaschinentabs/48-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkte/reiniger/ultimate-spulmaschinentabs/54-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkte/reiniger/quantum-spuelmaschinentabs/58-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkte/reiniger/power-spuelmaschinentabs/30-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7ab6d3ebd118b9ae/67b4dbabe2f81673baf1414a/wbvkezbrxnqius26vq3a.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/products/detergents/quantum-ultimate-dishwasher-tablets/32-lemon/',
        text: 'DISCOVER  ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6753692fae750683/67b4dcd6149b7e21550df09c/tough-on-grease-outline-red.webp',
          name: 'TOUGH ON GREASE',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6506882e7db57a44/67b4dce73875e361ee3b13f9/dried-on-stains-outline-red.webp',
          name: '<strong>48</strong>HR<br/>DRIED-ON STAINS',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt19a9e63f052e5a31/67b5ebdd531f5771c5136a7e/ultimate_plus_tablets_lemon.png',
      cardStyle: 'black',
      button: {
        link: '/products/',
        text: 'DISCOVER OUR BEST, ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blte281a0a72aa7c162/67b4dcf7531f5773421363df/extra-cleaning-power-outline-gold.webp',
          name: '<strong>3X</strong><br/>EXTRA CLEANING POWER',
          styleType: 'text-on-image',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt1ead84837aa3b96d/67b4dd0c17e9af3fa3f00d30/tough-on-grease-gold-outline-gold.webp',
          name: 'TOUGH ON GREASE',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt5aec89e568efcf46/67b4dd1dd8d35d6cfec38bef/burnt-on-stains-outline-gold.webp',
          name: 'BURNT ON STAINS',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt607d4320a8fd687f/67b4dbc12619f73383a6d1f9/zudd2nfxo0hdrzrcfbnc.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/products/detergents/quantum/40-lemon/',
        text: 'DISCOVER  QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6753692fae750683/67b4dcd6149b7e21550df09c/tough-on-grease-outline-red.webp',
          name: 'TOUGH ON GREASE',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishProductCardsMockAR = {
  cards: [
    {
      productName: 'فينش ألتيميت',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7ab6d3ebd118b9ae/67b4dbabe2f81673baf1414a/wbvkezbrxnqius26vq3a.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/ar/products/detergents/quantum-ultimate-dishwasher-tablets/32-lemon/',
        text: 'اكتشف ألتيميت',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6753692fae750683/67b4dcd6149b7e21550df09c/tough-on-grease-outline-red.webp',
          name: 'قوي على الشحوم',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6506882e7db57a44/67b4dce73875e361ee3b13f9/dried-on-stains-outline-red.webp',
          name: 'يزيل البقع المجففة لمدة 48 ساعة',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'فينش ألتيميت بلس',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt19a9e63f052e5a31/67b5ebdd531f5771c5136a7e/ultimate_plus_tablets_lemon.png',
      cardStyle: 'black',
      button: {
        link: '/ar/products/',
        text: 'اكتشف الأفضل لنا, ألتيميت بلس',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blte281a0a72aa7c162/67b4dcf7531f5773421363df/extra-cleaning-power-outline-gold.webp',
          name: 'قدرة فائقة على التنظيف',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt1ead84837aa3b96d/67b4dd0c17e9af3fa3f00d30/tough-on-grease-gold-outline-gold.webp',
          name: 'قوي على الشحوم',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt5aec89e568efcf46/67b4dd1dd8d35d6cfec38bef/burnt-on-stains-outline-gold.webp',
          name: 'الدهون المحروقة',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'فينش كوانتم',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt607d4320a8fd687f/67b4dbc12619f73383a6d1f9/zudd2nfxo0hdrzrcfbnc.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/ar/products/detergents/quantum/40-lemon/',
        text: 'اكتشف كوانتم',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt6753692fae750683/67b4dcd6149b7e21550df09c/tough-on-grease-outline-red.webp',
          name: 'قوي على الشحوم',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  description:
    'Melden Sie sich über das folgende Formular für den Finish Newsletter an und bleiben Sie immer auf dem neuesten Stand rund um Finish! Mit betätigen des ANMELDEN-Buttons willigen Sie ein, von RB Hygiene Home Deutschland GmbH Informationen zu aktuellen Angeboten, Aktionen und anderen Neuigkeiten zur Finish Marke per E-Mail zu erhalten.',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
